export enum PLATFORM {
  PLATFORM_FACEBOOK_GROUP = 3,
  PLATFORM_FACEBOOK = 1,
  PLATFORM_TIKTOK = 2,
  PLATFORM_ZALO_OA = 4,
  PLATFORM_YOUTUBE = 5,
  PLATFORM_INSTAGRAM = 6,
  PLATFORM_LINKEDIN = 7,
  PLATFORM_PINTEREST = 8,
  PLATFORM_X = 9,
  PLATFORM_THREADS = 10,
  PLATFORM_GOOGLE_BUSINESS = 11,
  PLATFORM_BLOG = 12,
  PLATFORM_WEBSITE = 13,
  NULL = 0,
}
