import {
  Radio,
  RadioChangeEvent,
  Select,
  Typography,
  Form,
  FormInstance,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { ItemHeader } from "./ItemHeader";
import { getListGroupChannels, loadListChannel } from "../../../api/channel";
import { getPlatformLabel, getUserInfor } from "../../../helper/common";
import {
  ICreateAutomaticScript,
  IUpdateAutomaticScrip,
} from "../../../dto/automatic-script/request/request";
import { useTranslation } from "react-i18next";

enum CHANNEL_TYPE {
  CHANNEL = 1,
  GROUP = 2,
}

export const ItemApplicableObject = ({
  itemSelectedToEdit,
  setData,
  form,
}: {
  itemSelectedToEdit?: ICreateAutomaticScript | IUpdateAutomaticScrip | any;
  setData: any;
  form: FormInstance;
}) => {
  const userInfo = getUserInfor();
  const [channelType, setChannelType] = useState<CHANNEL_TYPE>(
    itemSelectedToEdit?.channel?.type || CHANNEL_TYPE.CHANNEL
  );

  const conditionTypeChannel = () => {
    return itemSelectedToEdit?.channel?.type === CHANNEL_TYPE.CHANNEL;
  };

  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<string[]>(
    conditionTypeChannel() ? itemSelectedToEdit?.channel?.ids : []
  );

  const [selectedGroupItems, setSelectedGroupItems] = useState<string[]>(
    !conditionTypeChannel() ? itemSelectedToEdit?.channel?.ids : []
  );
  const [options, setOptions] = useState<any[]>([]);
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);

  const [channels, setChannels] = useState<any[]>([]);
  const [groupChannels, setGroupChannels] = useState<any[]>([]); 

  const onChange = (e: RadioChangeEvent) => {
    setChannelType(e.target.value);
    setData((pre: any) => ({
      ...pre,
      channel: {
        type: e.target.value,
        ids:
          e.target.value === CHANNEL_TYPE.CHANNEL
            ? selectedItems
            : selectedGroupItems,
      },
      channelIds:
        e.target.value === CHANNEL_TYPE.CHANNEL
          ? selectedItems
          : selectedGroupItems,
    }));

    form.setFieldsValue({
      channel_ids:
        e.target.value === CHANNEL_TYPE.CHANNEL
          ? selectedItems
          : selectedGroupItems,
    });
  };

  useEffect(() => {
    const fetchChannels = async () => {
      setLoadingOptions(true);
      try {
        const [channelResponse, groupChannelResponse] = await Promise.all([
          loadListChannel(userInfo.last_project_active, { status: 1, is_error: 0 }),
          getListGroupChannels(userInfo.last_project_active),
        ]);
  
        const channelData = channelResponse.data.channel;
        const groupChannelData = groupChannelResponse.data;
  
        const channelInfoSelected = itemSelectedToEdit?.channel_info?.full_info || [];
        const mergedChannels = channelData.concat(
          channelInfoSelected.filter(
            (item2: any) => !channelData.some((item1: any) => item1._id === item2._id)
          )
        );
        const mergedGroupChannels = groupChannelData.concat(
          channelInfoSelected.filter(
            (item2: any) => !groupChannelData.some((item1: any) => item1._id === item2._id)
          )
        );
  
        setChannels(mergedChannels);
        setGroupChannels(mergedGroupChannels);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingOptions(false);
      }
    };
  
    fetchChannels();
  }, []);

  useEffect(() => {
    if (channelType === CHANNEL_TYPE.CHANNEL) {
      const grouped = channels.reduce(
        (groups: Record<string, any[]>, item: any) => {
          const platform = getPlatformLabel(item.platform);
          if (!groups[platform]) {
            groups[platform] = [];
          }
          groups[platform].push({
            value: item._id,
            label: item.name,
          });
          return groups;
        },
        {}
      );
  
      const groupedOptions = Object.entries(grouped).map(([platform, opts]) => ({
        label: platform,
        options: opts,
      }));
  
      setOptions(groupedOptions);
    } else {
      setOptions(
        groupChannels.map((item: any) => ({
          value: item._id,
          label: item.name,
        }))
      );
    }
  }, [channelType, channels, groupChannels]);

  useEffect(() => {
    setData((pre: any) => ({
      ...pre,
      channel: {
        type: channelType,
        ids: conditionTypeChannel() ? selectedItems : selectedGroupItems,
      },
      channelIds: conditionTypeChannel() ? selectedItems : selectedGroupItems,
    }));

    form.setFieldsValue({
      channel_ids: conditionTypeChannel() ? selectedItems : selectedGroupItems,
    });
  }, [channelType, selectedItems, selectedGroupItems]);

  return (
    <>
      <div className={`flex p-4 bg-gray-100 items-center gap-2 rounded-t-lg`}>
        <ItemHeader title={t("campaign.applicable_object")} />
        <div className="pl-8 items-center">
          <Radio.Group onChange={onChange} value={channelType}>
            <Radio value={CHANNEL_TYPE.CHANNEL}>{t("filter.channel")}</Radio>
            <Radio className="pl-2" value={CHANNEL_TYPE.GROUP}>
              {t("filter.group-channel")}
            </Radio>
          </Radio.Group>
        </div>
      </div>
      <div className={`p-4 bg-white rounded-b-lg`}>
        <Form.Item
          // className="m-0"
          name="channel_ids"
          rules={[{ required: true, message: t("campaign.require_channel") }]}
        >
          {loadingOptions ? (
            <div style={{ textAlign: "center", padding: "10px 0" }}>
              <Spin tip={"Loading..."} />
            </div>
          ) : (
            <Select
              mode="multiple"
              placeholder={
                loadingOptions
                  ? "Loading..."
                  : t("campaign.choose_channel")
              }
              value={conditionTypeChannel() ? selectedItems : selectedGroupItems}
              onChange={
                conditionTypeChannel() ? setSelectedItems : setSelectedGroupItems
              }
              style={{ width: "100%" }}
              options={loadingOptions ? [] : options}
            />
          )}
        </Form.Item>
      </div>
    </>
  );
};
